/*!

=========================================================
* NextJS Material Kit v1.0.0 based on Material Kit Free - v2.0.2 (Bootstrap 4.0.0 Final Edition) and Material Kit React v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/nextjs-material-kit
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/nextjs-material-kit/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import App from "next/app";
import Head from "next/head";
import 'assets/style.css'
import "assets/scss/nextjs-material-kit.scss?v=1.0.0";

import { primaryColor } from "assets/jss/nextjs-material-kit.js";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { theme } from "assets/theme";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "assets/scss/swiper/swiper-vars.scss";

export default class MyApp extends App {
  componentDidMount() {
    let comment = document.createComment(`

=========================================================
* NextJS Material Kit v1.0.0 based on Material Kit Free - v2.0.2 (Bootstrap 4.0.0 Final Edition) and Material Kit React v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/nextjs-material-kit
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/nextjs-material-kit/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

`);
    document.insertBefore(comment, document.documentElement);
    
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});

    const script = document.createElement("script");
    script.src = "https://ww1.getasugar.de/js/container_nrZy0wOw.js";
    script.async = true;

    document.body.appendChild(script);

    let params = new URLSearchParams(location.search)
    if(params.has('utm_source') || params.has('utm_medium') || params.has('utm_campaign') || params.has('gas_site') || params.has('gas_place')) {
      let source = {
        source: params.get('utm_source'),
        medium: params.get('utm_medium'),
        campaign: params.get('utm_campaign'),
        site: params.get('gas_site'),
        placement: params.get('gas_place')
      }
      if(!localStorage.getItem('initial_source')) {
        localStorage.setItem('initial_source', JSON.stringify(source))
      }
      localStorage.setItem('current_source', JSON.stringify(source))
    }
    if(params.has('sst') && params.has('sstid')) {
      localStorage.setItem('current_sst', JSON.stringify({
        sst: params.get('sst'),
        sstid: params.get('sstid')
      }))
    }
    if(params.has('promo')) {
      localStorage.setItem('currentPromo', params.get('promo'))
    }
  }
  static async getInitialProps({ Component, router, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps };
  }
  render() {
    const { Component, pageProps, err } = this.props;

    return (
      <React.Fragment>
        <Head>
          <title>
            Sugardaddy sucht Sugarbabe | getasugar - das Sugar Dating Portal
          </title>
          <meta name="theme-color" content="#542687" />
          <meta
            name="description"
            content="Sugardaddy finden: Erfolgreiche Gentlemen treffen auf attraktive Damen (Sugarbabes) | niveauvolle Dating Plattform für die, die Luxus & Lifestyle lieben"
          />
        </Head>
        <ThemeProvider theme={theme}>
          <Component {...pageProps} err={err} />
        </ThemeProvider>
      </React.Fragment>
    );
  }
}
